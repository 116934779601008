/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        // Make clickable the categories icons under home hero
        $('.js-categories__link').click(function(){
          window.location = $(this).data('link');
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  // Remodal video player: Stop playing after closing modal
  $(document).on('closing', '.remodal.video-player', function () {
    var iframe = $('.remodal.video-player').find('iframe');
    iframe.attr('src', iframe.attr('init-src')+"&autoplay=0");
  });
  $(document).on('opening', '.remodal.video-player', function () {
    var iframe = $('.remodal.video-player').find('iframe');
    iframe.attr('src', iframe.attr('init-src')+"&autoplay=1");
  });

  // Keep hero image proportions (3:1)
  $( window ).resize(function() {

    var hero = $('.keep-constraints'),
    h1 = hero.find('h1'),
    ratioX =3; // 3
    var contentsHeight = 0;
    hero.children().each(function(){
      contentsHeight += $(this).height();
    });

    var newHeight  = $( window ).width() / ratioX;
    var padding = (newHeight - contentsHeight )/2;

    // Don't use paddings less than 20px
    if(padding < 20){
      padding = 20;
    }

    hero.css('padding', ( padding )+'px 0' );

  });
  $( window ).resize();

})(jQuery); // Fully reference jQuery after this point.
